import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby" 
// import data from `/d/<hash>.json`

function Seo({ share, url, title, description, keywordsString, meta }) {
  // const { site } = useStaticQuery(
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            keywords
          }
        }
      }
    `
  )

  const site = data.site;
  const metadata = site.siteMetadata;
  const lang = "en-US";
  const xmlns = "http://ogp.me/ns#"
  let metaDescription = description || metadata.description;
  let seoTitle = title || metadata.title;
  let seoUrl = url || metadata.siteUrl;
  let seoKeywords = keywordsString || metadata.keywords;

  if (share) {
    if (share.excerpt)
      metaDescription = share.excerpt;

    seoUrl = share.url;
    seoTitle = share.title;

    if (share.tagsString)
      seoKeywords = share.tagsString;
  }

  // console.log(seoTitle);
  // console.log(seoUrl);
  // console.log(seoKeywords);

  if (metaDescription.length > 160)
    metaDescription = metaDescription.substring(0, 160) + '...';

  // console.log(metaDescription);

  return (
    <Helmet
      htmlAttributes={{
        lang,
        "xmlns:og": xmlns, 
      }}
      title={seoTitle}
      //titleTemplate={`%s | ${site.siteMetadata.title}`}
      titleTemplate={`%s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: seoTitle,
        },
        {
          property: `og:url`,
          content: seoUrl,
        },
        {
          property: `og:site_name`,
          content: metadata.title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: metadata.author,
        },
        {
          name: `twitter:title`,
          content: seoTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: seoKeywords,
        }
      ]
        .concat(meta)
      }
    />
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  url: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywordsString: PropTypes.string,
  title: PropTypes.string,
}

export default Seo
